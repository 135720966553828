export enum ExitCode {
    DEFAULT = 100,
    USER = 101,
    UNLOAD = 103,
    ERROR = 105,
    CLIENTDISCONNECT = 107,
    TIMEOUT = 108,
    HOSTKICK = 201,
    HOST = 202,
    HOSTLOGOUT = 203,
    HOSTRELOGIN = 204,
    HOSTSENDFAIL = 205,
    HOSTDEVICEFAIL = 206,
    HOSTSTILLPICTURE = 207,
    HOSTCPUOVERLOAD = 208,
    HOSTMICRONOPEGEL = 209,
    HOSTVIDEOFAIL = 210,
    EXPIRED = 211,
    SERVER_ADMIN_OFF = 212,
    HOSTSENDRESTART = 213,
    ADMINKICK = 301,
    PREVIEW_LIMIT_HOST = 401,
    PREVIEW_LIMIT_HOUR = 402,
    PREVIEW_OFFLINE = 403,
    PREVIEW_SINGLE = 404,
    PREVIEW_FSK_DENIED = 405,
    PREVIEW_ACCESS_DENIE = 406,
    PREVIEW_DISABLED = 407,
    VIDEOTIMELIMIT = 501,
    TEXTTIMELIMIT = 502,
    FREETIMELIMIT = 503,
    FSKDENIED = 601,
    AVSCONVERSION = 602,
    PREPAID_TIMEOUT = 901,
    CLIENTNEWCHAT = 902,
    VOICECALLHANGUP = 903,
    VOICECALLNEWCHAT = 904,
    GWKILL = 905,
    HOSTSINGLE = 906,
    VIDEOFAILHOST = 907,
    VIDEOFAILGUEST = 910,
    CLIENTLOCK = 920,
    HOSTLOCK = 921,
    TIMEOUTNOOP = 990,
    PARTNERKICK = 991,
    PHPGENERAL = 995,
    VIDEOTIMEOUTHOST = 996,
    VIDEOTIMEOUTGUEST = 997,
    SERVERDOWN = 999
}
