[HTMLCanvasElement.prototype].forEach((item) => {
    if (Object.prototype.hasOwnProperty.call(item, 'toBlob')) {
        return;
    }

    Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value: function(
            callback: (b: Blob) => any,
            type: string,
            quality: number
        ) {
            setTimeout(() => {
                const binStr = atob(
                    this.toDataURL(type, quality).split(',')[1]
                );
                const len = binStr.length;
                const arr = new Uint8Array(len);

                for (let i = 0; i < len; i++) {
                    arr[i] = binStr.charCodeAt(i);
                }

                callback(new Blob([arr], { type: type || 'image/png' }));
            });
        }
    });
});
