import { Callbacks } from '../../callbacks';
import { IPlayer } from '../../iplayer';
import { PlayerFactory } from '../../player-factory';
import { BrowserName, isBrowserVersion } from '../../utils';
import { dummy, flashInfo, RtmpPlayer, swfFails } from './rtmp-player';
import { RtmpPlayerConfig } from './rtmp-player-config';

export class RtmpPlayerFactory implements PlayerFactory {
    public readonly format = 'rtmp';
    private config: RtmpPlayerConfig;
    constructor(config?: RtmpPlayerConfig) {
        this.config = config || {};
    }
    public isSupported(): boolean {
        const mver = flashInfo().major;
        let supported =
            mver === 999
                ? swfFails <= 1
                : swfFails <= 4 &&
                  (mver > 11 || (mver === 11 && flashInfo().minor >= 3));
        // special handling for zoomed Firefox
        if (
            window &&
            window.devicePixelRatio > 1 &&
            isBrowserVersion(BrowserName.FIREFOX)
        ) {
            supported = false;
        }
        return supported;
    }
    public async create(callbacks: Callbacks): Promise<IPlayer> {
        if (this.config.onPreInit) {
            this.config.onPreInit();
        }
        let canPublish = true;
        if (typeof this.config.useUpstream !== 'undefined') {
            canPublish = this.config.useUpstream;
        }
        return new RtmpPlayer(
            callbacks,
            this.config.swfPath || 'player.swf',
            this.config.timeout || 8000,
            this.config.onInit || dummy,
            canPublish
        );
    }
}
