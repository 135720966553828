import { Provider } from 'vchat-core';
import { JpegUploadSettings } from './jpeg-upload-settings';

export class VideoElementFrameProvider implements Provider {
    private _errorCallback?: (error: Error) => void;
    private _canvas: HTMLCanvasElement = document.createElement('canvas');
    private _videoElement: HTMLVideoElement;
    private _jpegUploadSettings: JpegUploadSettings;

    constructor(
        videoElement: HTMLVideoElement,
        jpegUploadSettings: JpegUploadSettings,
        errorCallback?: (error: Error) => void
    ) {
        if (!videoElement) {
            throw new Error('videoElement must not be undefined');
        }
        this._errorCallback = errorCallback;
        this._videoElement = videoElement;
        this._jpegUploadSettings = jpegUploadSettings;

        const mediaStream: MediaStream = videoElement.srcObject as MediaStream;
        const videoTrackSettings = mediaStream
            .getVideoTracks()[0]
            .getSettings();
        const aspectRatio =
            videoTrackSettings.aspectRatio ||
            videoTrackSettings.width / videoTrackSettings.height;

        this._canvas.width = this._jpegUploadSettings.width;
        this._canvas.height = this._jpegUploadSettings.width / aspectRatio;
    }

    public async getSnapshot(): Promise<Blob> {
        this._canvas
            .getContext('2d')
            .drawImage(
                this._videoElement,
                0,
                0,
                this._canvas.width,
                this._canvas.height
            );

        return new Promise<Blob>((res) =>
            this._canvas.toBlob(
                res,
                'image/jpeg',
                this._jpegUploadSettings.quality
            )
        );
    }
    public onError?(error: Error): void {
        if (this._errorCallback) {
            this._errorCallback(error);
        }
    }
}
