export interface Logger {
    log: (message?: any, ...optionalParams: any[]) => void;
    warn: (message?: any, ...optionalParams: any[]) => void;
    error: (message?: any, ...optionalParams: any[]) => void;
}

export let logger: Logger = {
    log: () => {
        /** noop */
    },
    warn: () => {
        /** noop */
    },
    error: () => {
        /** noop */
    }
};

export function setLogger(value: Logger): void {
    logger = value;
}
