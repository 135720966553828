/* eslint-disable unicorn/prefer-node-append */
[Element.prototype, Document.prototype, DocumentFragment.prototype].forEach(
    (item) => {
        if (Object.prototype.hasOwnProperty.call(item, 'append')) {
            return;
        }

        Object.defineProperty(item, 'append', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function append(...args) {
                const argArr = Array.prototype.slice.call(args),
                    docFrag = document.createDocumentFragment();

                argArr.forEach(function(argItem) {
                    const isNode = argItem instanceof Node;
                    docFrag.appendChild(
                        isNode
                            ? argItem
                            : document.createTextNode(String(argItem))
                    );
                });

                this.appendChild(docFrag);
            }
        });
    }
);
