import { Callbacks } from './callbacks';
import { IPlayer } from './iplayer';
import { PlayerFactory } from './player-factory';

// Utility class
export class PlayerFactoryLoader implements PlayerFactory {
    public format: string;
    private loader: () => Promise<PlayerFactory>;
    private factory: PlayerFactory;
    constructor(format: string, loader: () => Promise<PlayerFactory>) {
        this.format = format;
        this.loader = loader;
    }
    public isSupported(): boolean {
        return !this.factory || this.factory.isSupported();
    }
    public async create(
        callbacks: Callbacks,
        video: HTMLVideoElement
    ): Promise<IPlayer> {
        if (this.factory) {
            return this.factory.create(callbacks, video);
        }

        this.factory = await this.loader();
        this.factory.create(callbacks, video);
    }
}
