/* eslint-disable unicorn/prefer-node-remove */
[Element.prototype, CharacterData.prototype, DocumentType.prototype].forEach(
    (item) => {
        if (Object.prototype.hasOwnProperty.call(item, 'remove')) {
            return;
        }

        Object.defineProperty(item, 'remove', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function remove() {
                if (this.parentNode !== null) this.parentNode.removeChild(this);
            }
        });
    }
);
