(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vchat-core"), require("platform"), require("hls.js"));
	else if(typeof define === 'function' && define.amd)
		define(["vchat-core", "platform", "hls.js"], factory);
	else if(typeof exports === 'object')
		exports["vchatPlayer"] = factory(require("vchat-core"), require("platform"), require("hls.js"));
	else
		root["vchatPlayer"] = factory(root["vchat-core"], root["platform"], root["hls.js"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__83__, __WEBPACK_EXTERNAL_MODULE__85__) {
return 