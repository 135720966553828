import { PlayerFactory } from '../../player-factory';
import { Callbacks } from '../../callbacks';
import { IPlayer } from '../../iplayer';
import { H5LivePlayer } from './h5live-player';

export class H5LivePlayerFactory implements PlayerFactory {
    public readonly format = 'h5live';
    isSupported(): boolean {
        return true;
    }
    async create(callbacks: Callbacks): Promise<IPlayer> {
        const playerDiv = document.createElement('div');

        playerDiv.id = 'h5_live_player';
        playerDiv.style.width = '100%';
        playerDiv.style.height = '100%';
        playerDiv.style.background = 'black';
        playerDiv.style.position = 'relative';

        return new H5LivePlayer(callbacks, playerDiv);
    }
}
