import { WebRTCPlayer } from './webrtc-player';

import { isWebRTCSupported, browser } from 'detectrtc';
import { Callbacks } from '../../callbacks';
import { IPlayer } from '../../iplayer';
import { PlayerFactory } from '../../player-factory';

export class WebRTCPlayerFactory implements PlayerFactory {
    public readonly format = 'webrtc';

    public isSupported(): boolean {
        return isWebRTCSupported && browser.name !== 'Edge'; //disable for edge as webrtc is currently broken there
    }
    public create(
        callbacks: Callbacks,
        video: HTMLVideoElement
    ): Promise<IPlayer> {
        if (!video) {
            video = document.createElement('video');
            video.muted = false;
            video.autoplay = true;
        }

        video.crossOrigin = 'anonymous';
        video.controls = false;
        video.style.width = '100%';
        video.style.height = '100%';

        const player = new WebRTCPlayer(video);

        return Promise.resolve(player);
    }
}
